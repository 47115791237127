import { AuthService } from './../../auth/auth.service';
import { DataService } from './../../../data.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  constructor(public dataService: DataService, public auth: AuthService) {}

  ngOnInit() {}
}
