import { MultilevelMenuService } from 'ng-material-multilevel-menu';
import { DataService } from './../../../data.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { EditNavComponent } from '../edit-nav/edit-nav.component';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  // items = [ 'Devices', 'Location', 'Setting'];
  items = [
    // {
    //   name: 'Location',
    //   path: 'home/location',
    //   childRoutes: [
    //     { name: 'View', path: 'home/location/view' },
    //     { name: 'Monitor', path: 'home/location/monitor' }
    //   ]
    // }
  ];
  appItems = [
    {
      label: 'Home',
      faIcon: 'fab fa-500px',
      link: 'home',
      items: [
        // {
        //   label: 'View',
        //   link: 'home/location/view',
        //   faIcon: 'fab fa-accusoft'
        // },
        // {
        //   label: 'Monitor',
        //   faIcon: 'fab fa-accessible-icon',
        //   link: 'home/location/monitor'
        // }
      ]
    }
    // {
    //   label: 'Item 2',
    //   icon: 'alarm',
    //   items: [
    //     {
    //       label: 'Item 2.1',
    //       link: '/item-2-1',
    //       icon: 'favorite'
    //     },
    //     {
    //       label: 'Item 2.2',
    //       link: '/item-2-2',
    //       icon: 'favorite_border'
    //     }
    //   ]
    // },
    // {
    //   label: 'Item 3',
    //   link: '/item-3',
    //   icon: 'offline_pin'
    // },
  ];
  constructor(
    private dataService: DataService,
    private multilevelMenuService: MultilevelMenuService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.dataService.listOfRoutes.subscribe(val => {
      // console.log('router list in SideNav: ', val);
      const node = this.multilevelMenuService.getMatchedObjectByUrl(
        this.appItems,
        val.join('/')
      );
      this.multilevelMenuService.selectMenuByID(node.id);
    });
  }

  selectedItem($event) {
    // console.log($event);
  }

  onEditNav() {
    this.dialog.open(EditNavComponent, {
      height: '909vh',
      width: '60vw',
      position: { left: '12.5em', top: '4em' }
    });
  }
}
