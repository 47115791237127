import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-folder-unit',
  templateUrl: './folder-unit.component.html',
  styleUrls: ['./folder-unit.component.scss']
})
export class FolderUnitComponent implements OnInit {
  @Input() name;
  @Input() last;
  isLast;
  @Input() first;
  isFirst;
  @Input() root;
  isRoot;
  @Input() hasNextChild;
  isHasNextChild;
  @Input() indexCol;
  @Input() indexRow;
  indexColNum;
  indexRowNum;

  @Output() addChildRow = new EventEmitter();
  @Output() addChildCol = new EventEmitter();
  @Output() removeUnit = new EventEmitter();
  constructor(private dataService: DataService) {}

  ngOnChanges() {
    this.isLast = this.last === 'true';
    this.isFirst = this.first === 'true';
    this.isRoot = this.root === 'true';
    this.isHasNextChild = this.hasNextChild === 'true';
    this.indexRowNum = Number(this.indexRow);
    this.indexColNum = Number(this.indexCol);
  }

  ngOnInit(): void {
    // console.log(typeof(this.isLast));
  }

  onRemoveUnit() {
    // console.log(this.indexCol, this.indexRow);
    this.dataService.removeTraverse(
      this.dataService.dataTree,
      this.indexRow,
      this.indexCol
    );
  }

  onAddChildRow() {
    // this.addChildRow.emit(null);
    // console.log('add index ', this.indexCol, this.indexRow);
    this.dataService.addRowTraverse(
      this.dataService.dataTree,
      this.indexRowNum,
      this.indexColNum
    );
  }

  onAddChildCol() {
    // this.addChildCol.emit(null);
    this.dataService.addColTraverse(
      this.dataService.dataTree,
      this.indexRowNum,
      this.indexColNum
    );
  }

  getStyle() {
    let height;
    if (this.indexColNum == 0 && this.indexRowNum == 0) {
      height = '7.5em';
    } else {
      height = '2em';
    }
    return {
      height: height
    };
  }
}
