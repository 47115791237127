<div class="container">

    <div *ngIf="items" class="unit">
        <!-- {{items?.indexCol}}
        {{items?.indexRow}} -->
        <app-folder-unit indexCol="{{items?.indexCol}}" indexRow="{{items?.indexRow}}" name="{{items?.name}}"
            root="{{items?.isRoot}}" first="{{items?.isFirst}}" last="{{items?.isLast}}"
            hasNextChild={{items?.hasNextChild}}></app-folder-unit>
    </div>

    <div class="child-row">
        <!-- recursive unit -->
        <!-- recursive row -->
        <ul *ngIf="childRow && childRow.length>0">
            <li *ngFor="let item of childRow">
                <app-folder-tree [itemRecursive]="item"></app-folder-tree>
            </li>
        </ul>
    </div>

</div>
<div class="child-col">
    <!-- recursive col -->
    <ul *ngIf="childCol && childCol.length>0">
        <li *ngFor="let item of childCol">
            <app-folder-tree [itemRecursive]="item"></app-folder-tree>
        </li>
    </ul>
</div>