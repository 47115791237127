import { Component, OnInit, Input } from '@angular/core';
import { TreeUnit } from '../../model/tree-unit.interface';

@Component({
  selector: 'app-folder-tree',
  templateUrl: './folder-tree.component.html',
  styleUrls: ['./folder-tree.component.scss']
})
export class FolderTreeComponent implements OnInit {
  @Input() itemRecursive;
  childRow;
  childCol;
  items;
  constructor() {}
  ngOnInit(): void {
    this.items = this.itemRecursive;
    // console.log('items: ',this.items);
    if (this.items) {
      this.childRow = this.items.childRow;
      this.childCol = this.items.childCol;
    }
  }
}
