import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public csrfToken$ = new BehaviorSubject<string | undefined>(undefined);
  private CSRF_TOKEN_STORAGE_KEY = 'cems_csrf_token';
  private fetchNewCsrfToken$!: Observable<{ token: string }>;

  private httpClient: HttpClient;

  constructor(handler: HttpBackend, private router: Router) {
    this.httpClient = new HttpClient(handler);
  }

  public hasValidCsrfToken(): boolean {
    const token = this.getCsrfToken();
    return token ? this.isCsrfTokenValid(token) : false;
  }

  public getCsrfToken(): string | undefined {
    return this.csrfToken$.value;
  }

  init() {
    console.log('auth init');

    this.csrfToken$.subscribe(jwtToken => {
      if (jwtToken) {
        localStorage.setItem(this.CSRF_TOKEN_STORAGE_KEY, jwtToken);
      }
    });

    this.fetchNewCsrfToken$ = this.getCsrfTokenRequest().pipe(
      tap({
        error: () => this.redirectToLoginScreen()
      }),
      share()
    );

    const token =
      this.getCsrfTokenFromUrl() || this.getCsrfTokenFromLocalStorage();

    if (token && this.isCsrfTokenValid(token)) {
      this.csrfToken$.next(token);
      return;
    } else {
      this.redirectToLoginScreen(); // change this for test purpose
    }
  }

  fetchNewCsrfToken() {
    return this.fetchNewCsrfToken$;
  }

  public getCsrfTokenRequest() {
    return this.httpClient
      .post<{ token: string }>(
        `${environment.IAMConfig.auth.baseUrl}auth/v1/saml/csrf`,
        {
          appId: environment.IAMConfig.auth.appId
        },
        {
          withCredentials: true
        }
      )
      .pipe(
        map(resp => {
          this.csrfToken$.next(resp.token);
          return resp;
        })
      );
  }

  isCSRFTokenExpired(token: string): boolean {
    const date = this.getTokenExpirationDate(token);

    if (!date) {
      return false;
    }
    return !(date.valueOf() > new Date().valueOf());
  }

  getTokenExpirationDate(token: string): Date | null {
    const decoded = jwtDecode<JwtPayload>(token, { header: true });

    if (decoded.exp === undefined) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  removeTokenFromLocalStorage() {
    localStorage.removeItem(this.CSRF_TOKEN_STORAGE_KEY);
  }

  private getCsrfTokenFromLocalStorage(): string | null {
    return localStorage.getItem(this.CSRF_TOKEN_STORAGE_KEY);
  }

  private getCsrfTokenFromUrl(): string | null {
    const hash = window.location.hash;
    if (hash && hash.startsWith('#token=')) {
      window.location.hash = '';
      return new HttpParams({ fromString: hash }).get('#token');
    }
  }

  private redirectToLoginScreen(): void {
    const redirectUrlEncoded = encodeURIComponent(window.location.href);
    window.location.href = `${environment.IAMConfig.auth.baseUrl}saml/sso/request?appId=${environment.IAMConfig.auth.appId}&redirectUrl=${redirectUrlEncoded}`;
  }

  private isCsrfTokenValid(token: string) {
    try {
      return !this.isCSRFTokenExpired(token);
    } catch (_) {
      return false;
    }
  }
}
