import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  typeCreate = 2;
  formCreateLocation;
  listLocationData = [];
  locationEdittingIndex;

  dataTree = {
    name: 'root',
    isRoot: true,
    indexCol: 0,
    indexRow: 0,
    childRow: [
      {
        name: 'test',
        isRoot: false,
        isLast: false,
        isFirst: true,
        hasNextChild: true,
        indexCol: 1,
        indexRow: 0,
        childRow: [],
        childCol: []
      },
      {
        name: 'test',
        isRoot: false,
        isLast: false,
        hasNextChild: true,
        indexCol: 2,
        indexRow: 0,
        childRow: [],
        childCol: []
      },
      {
        name: 'test',
        isRoot: false,
        isLast: true,
        indexCol: 3,
        indexRow: 0,
        childRow: [],
        childCol: []
      }
    ],
    childCol: [
      {
        name: 'test',
        isRoot: false,
        isLast: true,
        indexCol: 0,
        indexRow: 1,
        childRow: [],
        childCol: []
      }
    ]
  };

  listOfRoutes = new BehaviorSubject<[]>([]);
  private readonly listOfRoutesExpose = this.listOfRoutes.asObservable();
  constructor() {}

  // Get last value without subscribing to the puppies$ observable (synchronously).
  getListRouterLink() {
    return this.listOfRoutes.getValue();
  }

  private _setListRouterLink(link): void {
    this.listOfRoutes.next(link);
  }

  addLink(links): void {
    const listOfLink = [...this.getListRouterLink(), ...links];
    this._setListRouterLink(listOfLink);
  }

  clearListLink() {
    this._setListRouterLink([]);
  }

  // removePuppy(link): void {
  //   const listOfLink = this.getListRouterLink().filter(p => p.id !== puppy.id);
  //   this._setPuppies(listOfLink);
  // }

  // data Tree
  removeTraverse(target, indexRow, indexCol) {
    for (const key in target) {
      if (typeof target[key] === 'object') {
        // do stuff
        // console.log(key, target[key]);
        if (
          target[key].indexRow == indexRow &&
          target[key].indexCol == indexCol
        ) {
          console.log('delete done', target[key]);
          delete target[key];
        } else {
          this.removeTraverse(target[key], indexRow, indexCol);
        }
      }
    }
  }

  addRowTraverse(target, indexRow, indexCol) {
    if (indexRow == 0) {
      this.dataTree.childRow[indexCol - 1].isLast = false;
      this.dataTree.childRow[indexCol - 1].hasNextChild = true;
      this.dataTree.childRow.push({
        name: 'test',
        isRoot: false,
        isFirst: false,
        isLast: true,
        hasNextChild: false,
        indexCol: Number(indexCol) + 1,
        indexRow: indexRow,
        childRow: [],
        childCol: []
      });
    } else if (indexCol == 0) {
      this.dataTree.childCol.push({
        name: 'test',
        isRoot: false,
        isLast: true,
        indexCol: 0,
        indexRow: 1,
        childRow: [],
        childCol: []
      });
    } else {
      for (const key in target) {
        if (typeof target[key] === 'object') {
          if (target[key].indexRow == indexRow && target[key].indexCol == 0) {
            target[key].childRow.push({
              name: 'test',
              isRoot: false,
              isLast: true,
              isFirst: true,
              hasNextChild: true,
              indexCol: indexCol + 1,
              indexRow: indexRow,
              childRow: [],
              childCol: []
            });
          } else {
            this.addRowTraverse(target[key], indexRow, indexCol);
          }
        }
      }
    }
  }

  addColTraverse(target, indexRow, indexCol) {
    for (const key in target) {
      if (typeof target[key] === 'object') {
        if (target[key].indexRow == indexRow && target[key].indexCol == 0) {
          target[key].childCol.push({
            name: 'test',
            isRoot: false,
            isFirst: true,
            hasNextChild: true,
            indexCol: indexCol,
            indexRow: indexRow + 1,
            childRow: [],
            childCol: []
          });
        } else {
          this.addColTraverse(target[key], indexRow, indexCol);
        }
      }
    }
    // }
  }
}
