import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, timer } from 'rxjs';
import { map, concatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  fuelCellPhase$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  outsideTemp$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  temperature$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  commonSupply$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  timeOffset$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  constructor(private http: HttpClient) {}

  fetchMe() {
    return this.http.get(
      'https://api-integration.viessmann-platform.io/user-registration/v2/users/me',
      { withCredentials: true }
    );
  }

  fetchFeatures() {
    timer(0, 3000)
      .pipe(
        concatMap(() => {
          return this.http.get(
            // tslint:disable-next-line:max-line-length
            'https://api-integration.viessmann-platform.io/operational-data/v2/installations/3804/gateways/7782093003592197/devices/0/features',
            { withCredentials: true }
          );
        })
      )
      .subscribe(
        success => {
          const payload = success as any;
          const fuelCell = this.getFeature(
            payload,
            'heating.fuelCell.operating.phase'
          );
          const outsideTemp = this.getFeature(
            payload,
            'heating.sensors.temperature.outside'
          );
          const temperature = this.getFeature(
            payload,
            'heating.boiler.temperature'
          );
          const commonSupply = this.getFeature(
            payload,
            'heating.boiler.sensors.temperature.commonSupply'
          );
          const timeOffset = this.getFeature(
            payload,
            'heating.device.time.offset'
          );

          payload.entities.forEach(entity => {
            if (entity.properties) {
              if (entity.properties.value) {
                if (
                  entity.properties.value.type === 'number' &&
                  entity.properties.value.value !== 0
                ) {
                  console.log(
                    entity.class[0] + ', ' + entity.properties.value.value
                  );
                }
              }
            }
          });

          this.fuelCellPhase$.next(fuelCell);
          this.outsideTemp$.next(outsideTemp);
          this.temperature$.next(temperature);
          this.commonSupply$.next(commonSupply);
          this.timeOffset$.next(timeOffset);
        },
        featureError => {
          alert('Oops, unable to fetch installation features');
        }
      );
  }

  private getFeature(featuresObject: any, featureNameParam: string): any {
    const entities: any[] = featuresObject.entities;
    return entities.find(entity => {
      const entityClass: string[] = entity.class;
      const featureName: string = entityClass[0];
      const featureFound: boolean = featureName === featureNameParam;
      return featureFound;
    });
  }
}
