<div class="container" *ngIf="name">
    <div *ngIf="isFirst" class="line-before"></div>

    <p>{{name}}</p>

    <span *ngIf="!isRoot" class='material-icons' (click)="onRemoveUnit()">remove_circle_outline</span>

    <div *ngIf="!isRoot" class="line-after" ></div>
    <span *ngIf="isLast" style="margin-left: 1.25em;position: absolute;left: 108%;" class="material-icons"
        (click)="onAddChildRow()">add_circle_outline</span>
</div>

<div *ngIf="name" class="line-under" [ngStyle]="getStyle()"></div>
<span *ngIf="name&&(isLast || isHasNextChild)" style="margin-left: 1.25em;" class="material-icons"
    (click)="onAddChildCol()">add_circle_outline</span>