// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  headerModuleApiUrl: 'https://api-integration.viessmann.com',
  accountIntegrationUrl: 'https://account-integration.viessmann.com',
  samlConfig: {
    requestLoginUrl: 'https://api-integration.viessmann.com/saml/sso/request',
    tryLoginUrl: 'https://api-integration.com/user-registration/v2/users/me',
    logoutUrl: 'https://api-integration.viessmann.com/saml/logout'
  },
  http: {
    translationsBaseUrl:
      'https://api-integration.viessmann.com/translations/v2/',
    usersUrl: 'https://api-integration.viessmann.com/users/v1/',
    purchasesUrl: 'https://api-integration.viessmann.com/purchases/v1/',
    legalService: 'https://api-integration.viessmann.com/legal/v3/',
    statusPage:
      'https://api-integration.viessmann.com/status/v1/apps/5kqk45hfwmxc/summary'
  },
  IAMConfig: {
    auth: {
      baseUrl: 'https://api-integration.viessmann.com/',
      appId: 'cems'
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
