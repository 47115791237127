import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';

@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss']
})
export class ShareDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

@NgModule({
  imports: [
    CommonModule,
    // RouterModule,
    // DxDropDownBoxModule,
    // DxListModule
    // DxDropDownButtonModule,
  ],
  declarations: [ShareDialogComponent],
  exports: [ShareDialogComponent]
})
export class ShareDialogModule { }
