import { RouterModule } from '@angular/router';
import {
  BrowserModule,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG
} from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialogModule } from '@angular/material/dialog';

import { GlobalHeaderModule } from '@vi/global-header';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PanelComponent } from './_shared/components/panel/panel.component';
import { IconButtonComponent } from './_shared/components/icon-button/icon-button.component';
import { LoginComponent } from './_shared/components/login/login.component';
import { BlankPageComponent } from './pages/blank-page/blank-page.component';
import { environment } from 'src/environments/environment';
import { HttpClientModule, HttpBackend } from '@angular/common/http';
import { HeaderComponent } from './_shared/components/header/header.component';
import { LoginPageComponent } from './pages/login/login-page.component';
import { ProductionComponent } from './pages/production/production.component';
import { BatteryComponent } from './pages/battery/battery.component';
import { SideNavComponent } from './_shared/components/side-nav/side-nav.component';
import { BreadcrumbComponent } from './_shared/components/breadcrumb/breadcrumb.component';
import { DragListComponent } from './_shared/components/drag-list/drag-list.component';
import { ShareDialogModule } from './_shared/components/share-dialog/share-dialog.component';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
  MultilevelMenuService,
  NgMaterialMultilevelMenuModule,
  ɵb
} from 'ng-material-multilevel-menu';
import { EditNavComponent } from './_shared/components/edit-nav/edit-nav.component';
import { FolderUnitComponent } from './_shared/components/folder-unit/folder-unit.component';
import { FolderTreeComponent } from './_shared/components/folder-tree/folder-tree.component';
import { FolderRowComponent } from './_shared/components/folder-row/folder-row.component';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    pinch: { enable: false },
    rotate: { enable: false }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    PanelComponent,
    IconButtonComponent,
    LoginComponent,
    BlankPageComponent,
    HeaderComponent,
    LoginPageComponent,
    ProductionComponent,
    BatteryComponent,
    BreadcrumbComponent,
    DragListComponent,
    SideNavComponent,
    EditNavComponent,
    FolderUnitComponent,
    FolderTreeComponent,
    FolderRowComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    NgxChartsModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatProgressSpinnerModule,
    DragDropModule,
    MatDialogModule,
    ShareDialogModule,
    RouterModule,
    MatSnackBarModule,
    GlobalHeaderModule.forRoot({
      baseUrl: environment.headerModuleApiUrl,
      accountAppUrl: environment.accountIntegrationUrl,
      appId: environment.IAMConfig.auth.appId
    }),
    // IAMModule.forRoot({
    //   saml: environment.samlConfig
    // })
    NgMaterialMultilevelMenuModule
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    HttpClientModule,
    MultilevelMenuService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
