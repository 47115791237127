import { AuthService } from './_shared/auth/auth.service';
import { DataService } from './data.service';
import { DragListComponent } from './_shared/components/drag-list/drag-list.component';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RouterOutlet, ActivatedRoute } from '@angular/router';
import {
  trigger,
  transition,
  style,
  query,
  animateChild,
  group,
  animate
} from '@angular/animations';
import { AppService } from './app-service.service';

export const slideInAnimation = trigger('routeAnimations', [
  transition('EnergyPage => ConsumptionPage', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ left: '100%' })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('300ms ease-in', style({ right: '100%' }))]),
      query(':enter', [animate('300ms ease-in', style({ left: '0%' }))])
    ]),
    query(':enter', animateChild())
  ]),
  transition('EnergyPage => AutarkyPage', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ left: '100%' })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('300ms ease-in', style({ right: '100%' }))]),
      query(':enter', [animate('300ms ease-in', style({ left: '0%' }))])
    ]),
    query(':enter', animateChild())
  ]),
  transition('EnergyPage => BatteryPage', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ left: '100%' })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('300ms ease-in', style({ right: '100%' }))]),
      query(':enter', [animate('300ms ease-in', style({ left: '0%' }))])
    ]),
    query(':enter', animateChild())
  ]),
  transition('ConsumptionPage => EnergyPage', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ right: '100%' })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('300ms ease-in', style({ right: '-100%' }))]),
      query(':enter', [animate('300ms ease-in', style({ right: '0%' }))])
    ]),
    query(':enter', animateChild())
  ]),
  transition('EnergyPage => ProductionPage', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ left: '100%' })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('300ms ease-in', style({ right: '100%' }))]),
      query(':enter', [animate('300ms ease-in', style({ left: '0%' }))])
    ]),
    query(':enter', animateChild())
  ]),
  transition('ProductionPage => ConsumptionPage', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ right: '100%' })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('300ms ease-in', style({ right: '-100%' }))]),
      query(':enter', [animate('300ms ease-in', style({ right: '0%' }))])
    ]),
    query(':enter', animateChild())
  ]),
  transition('ProductionPage => AutarkyPage', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ left: '100%' })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('300ms ease-in', style({ right: '100%' }))]),
      query(':enter', [animate('300ms ease-in', style({ left: '0%' }))])
    ]),
    query(':enter', animateChild())
  ]),
  transition('ConsumptionPage => ProductionPage', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ left: '100%' })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('300ms ease-in', style({ right: '100%' }))]),
      query(':enter', [animate('300ms ease-in', style({ left: '0%' }))])
    ]),
    query(':enter', animateChild())
  ]),
  transition('AutarkyPage => ProductionPage', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ right: '100%' })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('300ms ease-in', style({ right: '-100%' }))]),
      query(':enter', [animate('300ms ease-in', style({ right: '0%' }))])
    ]),
    query(':enter', animateChild())
  ]),
  transition('AutarkyPage => BatteryPage', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ left: '100%' })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('300ms ease-in', style({ right: '100%' }))]),
      query(':enter', [animate('300ms ease-in', style({ left: '0%' }))])
    ]),
    query(':enter', animateChild())
  ]),
  transition('BatteryPage => AutarkyPage', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ right: '100%' })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('300ms ease-in', style({ right: '-100%' }))]),
      query(':enter', [animate('300ms ease-in', style({ right: '0%' }))])
    ]),
    query(':enter', animateChild())
  ])
]);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation]
})
export class AppComponent implements OnInit {
  title = 'frontend-cems';
  userAuthenticated = true;
  authorizing = false;

  constructor(
    private appService: AppService,
    public dataService: DataService,
    private changeDetector: ChangeDetectorRef,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.authService.init();
    // this.authorizing = true;
    // this.appService.fetchMe().subscribe(
    //   req => {
    //     this.userAuthenticated = true;
    //     this.authorizing = false;
    //     this.appService.fetchFeatures();
    //   },
    //   error => {
    //     this.userAuthenticated = false;
    //     this.authorizing = false;
    //   }
    // );
  }

  ngAfterViewChecked(): void {
    //Called after every check of the component's view. Applies to components only.
    //Add 'implements AfterViewChecked' to the class.
    this.changeDetector.detach();
    this.changeDetector.detectChanges();
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation
    );
  }
}
