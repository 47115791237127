<!-- <ul>
    <li *ngFor="let item of items"  >
        <div routerLink={{item.path}}>
            {{item.name}}
        </div>
        <ul style="margin-left: 1em;">
            <li *ngFor="let childItem of item.childRoutes" >
                <div routerLink="{{childItem.path}}">
                    {{childItem.name}}
                </div>
            </li>
        </ul>
    </li>
</ul> -->
<!-- <div style="display: flex;align-items: center;padding: 0.5em;gap: 1em">
    Navigation Structure
    <span (click)="onEditNav()" class="material-icons">edit_off</span>
</div>
<ng-material-multilevel-menu [items]='appItems' (selectedItem)="selectedItem($event)">
</ng-material-multilevel-menu> -->
<div style="width: 15em;">
     
</div>