<app-header></app-header>
<ng-container >
<!-- <ng-container > -->
    <mat-sidenav-container class="sidenav-container">
        <!-- <div class="container" [@routeAnimations]="prepareRoute(outlet)"
        [ngStyle.lg]="{ 'width.%': 50, 'margin-left.%': 25 }">
    </div> -->
        <mat-sidenav mode="side" opened>
            <app-side-nav></app-side-nav>
        </mat-sidenav>
        <mat-sidenav-content style="position:relative ;">   
            <!-- Main content -->
            <!-- <app-breadcrumb style="position:sticky ;"></app-breadcrumb> -->
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</ng-container>
<ng-container *ngIf="!userAuthenticated">
    <div class="login-text">
        <p style="margin-bottom:0;">Welcome to CEMS</p>
        <div>Commercial Energy Management System</div>
        <p>Please log in.</p>
    </div>
</ng-container>
<div style="height:80%;" fxLayout="column" fxLayoutAlign="center center" *ngIf="authorizing">
    <mat-spinner></mat-spinner>
    authorizing...
</div>