import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-edit-nav',
  templateUrl: './edit-nav.component.html',
  styleUrls: ['./edit-nav.component.scss']
})
export class EditNavComponent implements OnInit {
  item;
  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    this.item = this.dataService.dataTree;
  }
}
